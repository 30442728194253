import React, {
  MouseEvent,
  RefObject,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  Skeleton,
  Typography
} from '@mui/material'
import SimpleBar from 'simplebar-react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Table from '../../Templates/_Table'
import THeader from '../../Modecules/Table/THeader'
import usePagination from '../../../hooks/usePagination'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { TABLE_ROW_NUMBER_PER_PAGE } from '../../../constants/constants'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import { IColumnProps } from '../../../types/_table'
import TBody from '../../Modecules/_Table/TBody'
import TRow from '../../Modecules/_Table/TRow'
import TCell from '../../Modecules/_Table/TCell'
import PageTemplate from '../PageTemplate'
import { useStyles } from '../../../themes'
import CustomButton from '../../Atoms/Buttons'
import Loader from '../../Atoms/Loader/Loader'
import Pagination from '../../Modecules/Pagination/Pagination'
import { ReactComponent as MenuIcon } from '../../../images/icon/menu.svg'
import { ReactComponent as Delete } from '../../../images/icon/delete.svg'
import { ReactComponent as Edit } from '../../../images/icon/edit-icon.svg'
import { ReactComponent as Corporation } from '../../../images/icon/no-device.svg'
// import dummyDeviceUrlMapping from '../../../mocks/data/device-url-mapping'
// import { TResponse } from '../../../types/notification'
import {
  // IDeviceUrlMappingListResponse,
  IDeviceUrlMappingResponse
} from '../../../types/deviceurlmapping'
import CustomTooltip from '../../Atoms/Tooltip'
import CommonDialog from '../../Atoms/Dialog'
import { ReactComponent as DeleteIconModal } from '../../../images/icon/modal-delete.svg'
import { ReactComponent as AddIcon } from '../../../images/icon/addIcon.svg'
import DeviceUrlMappingForm from '../../Modecules/DeviceUrlMappingForm'
import CustomInput from '../../Atoms/Inputs'
import useShippingImportStyles from '../ShippingDataImport/style'
import CustomSelect from '../../Atoms/CustomSelect'
import { OptionsType } from '../../../types/common'
import { useServerRegionListQuery } from '../../../state/features/serverRegion/serverRegionSlice'
import {
  useLazyDeviceUrlMappingListQuery,
  useEditDeviceUrlMappingMutation,
  useCreateDeviceUrlMappingMutation,
  useDeleteDeviceUrlMappingMutation
} from '../../../state/features/deviceurlmapping/deviceUrlMappingSlice'
import { IDeivceImportPayload } from '../../../types/deviceimport'
import { useUrlTypeListQuery } from '../../../state/features/urlType/urlTypeSlice'

const DeviceUrlMappingList = () => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })
  const [filterQuery, setFilterQuery] = useState({
    sortDirection: '',
    colName: '',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0
  })
  const classes = useStyles()
  const modalClass = useShippingImportStyles()
  const { t } = useTranslation()
  const buttonRefs = useRef<Array<RefObject<HTMLButtonElement>>>([])
  const [deleteDeviceUrlMappingModal, setDeleteDeviceUrlMappingModal] =
    useState<{ state: boolean; deviceUrlMappingId: number }>({
      state: false,
      deviceUrlMappingId: 0
    })
  const [addDeviceUrlMappingModal, setAddDeviceUrlMappingModal] =
    useState<boolean>(false)
  const [updateDeviceUrlMappingModal, setUpdateDeviceUrlMappingModal] =
    useState<{ state: boolean; id: number }>({
      state: false,
      id: 0
    })

  const [isEditable, setIsEditable] = useState<boolean>(false)

  // const [isFieldEdited, setIsFieldEdited] = useState<boolean>(false)
  const [initialValues, setInitialValues] =
    useState<IDeviceUrlMappingResponse | null>(null)

  // const deviceUrlMappingListData: TResponse<IDeviceUrlMappingListResponse> =
  //   dummyDeviceUrlMapping
  // const deviceUrlMappingListIsLoading = false
  // const deviceUrlMappingListIsSuccess = true
  // const deviceUrlMappingListIsFetching = false

  const {
    // handleSubmit,
    formState: { errors },
    register,
    // setError,
    // reset,
    watch,
    setValue,
    getValues
  } = useForm()
  const formValues = watch()

  const [
    trigger,
    {
      data: deviceUrlMappingListData,
      isLoading: deviceUrlMappingListIsLoading,
      isSuccess: deviceUrlMappingListIsSuccess,
      isFetching: deviceUrlMappingListIsFetching,
      isError: deviceUrlMappingListIsError
    }
  ] = useLazyDeviceUrlMappingListQuery()

  const {
    currentPage,
    // setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(
    TABLE_ROW_NUMBER_PER_PAGE[2],
    0,
    deviceUrlMappingListData
      ? deviceUrlMappingListData?.data.total_device_mapping_list
      : 0
  )

  const columnProps: IColumnProps = [
    {
      key: 'server_name',
      name: 'Server Name',
      colWidth: 165,
      colMaxWidth: 165,
      isSort: false
    },
    {
      key: 'server_region',
      name: 'Server Region',
      colWidth: 93,
      colMaxWidth: 93,
      isSort: false
    },
    {
      key: 'endpoint_name',
      name: 'Endpoint Name',
      colWidth: 145,
      colMaxWidth: 145,
      isSort: false
    },
    {
      key: 'url',
      name: 'URL',
      colWidth: 785,
      colMaxWidth: 785,
      isSort: false
    },
    {
      key: 'action',
      name: '',
      colWidth: 65,
      colMaxWidth: 65,
      isSort: false
    }
  ]

  const sortFieldsNameReplace = (substr: string) => {
    if (substr === 'server_name') {
      return 'serverName'
    }
    if (substr === 'server_region') {
      return 'server_region'
    }
    if (substr === 'endpoint_name') {
      return 'destinationInfo'
    }
    if (substr === 'url') {
      return 'schedulerStartDate'
    }

    return ''
  }

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection:
        sortConfigUpdate.key === '' ? '' : sortConfigUpdate.direction,
      colName:
        sortConfigUpdate.direction === DIRECTION_TYPE.none
          ? ''
          : sortFieldsNameReplace(key)
    })
    changeCurrentPage(0)
  }

  const { data: serverRegionListData } = useServerRegionListQuery()
  const { data: urlTypeListData } = useUrlTypeListQuery()

  const [
    editDeviceUrlMapping,
    {
      data: editData,
      isError: isEditError,
      error: editError,
      isLoading: isEditLoading,
      isSuccess: isEditSuccess
    }
  ] = useEditDeviceUrlMappingMutation()
  const [
    createDeviceUrlMapping,
    {
      data: createData,
      isError: isCreateError,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess
    }
  ] = useCreateDeviceUrlMappingMutation()

  const [
    deleteDeviceUrlMapping,
    {
      data: deleteData,
      isError: isDeleteError,
      error: deleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess
    }
  ] = useDeleteDeviceUrlMappingMutation()

  useEffect(() => {
    if (updateDeviceUrlMappingModal.state) {
      // const { server_name, server_region, endpoint_name, url } =
      //   updateDeviceUrlMappingModal.data

      // Set initial values when the modal is opened
      setInitialValues({
        id: updateDeviceUrlMappingModal.id,
        server_name: getValues('server_name') as string,
        server_region: getValues('server_region') as string,
        endpoint_name: getValues('endpoint_name') as string,
        url: getValues('url') as string
      })

      setIsEditable(false)
    }
  }, [updateDeviceUrlMappingModal, getValues])

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))
  }, [currentPage, rowsPerPage])

  // Watch all form values

  // useEffect to log form values whenever they change
  useEffect(() => {
    if (updateDeviceUrlMappingModal.state && initialValues) {
      const isEdited = Object.keys(initialValues).some(
        (key) =>
          formValues[key as keyof IDeviceUrlMappingResponse] !==
            initialValues[key as keyof IDeviceUrlMappingResponse] &&
          key !== 'id'
      )

      setIsEditable(isEdited)
    }
  }, [formValues, initialValues, updateDeviceUrlMappingModal.state])

  const [openPopperId, setOpenPopperId] = useState<string | null>(null)
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false)

  const handleDeleteMenuOpen = (
    event:
      | MouseEvent<Element, globalThis.MouseEvent>
      | MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    id: string
  ) => {
    event.stopPropagation()

    if (openPopperId === id && isPopperOpen) {
      setIsPopperOpen(!openPopperId)
    } else {
      setIsPopperOpen(true)
    }
    setOpenPopperId(id)
  }

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      // Check if the event target is not inside any of the buttonRefs
      if (
        !buttonRefs.current.some((ref) =>
          ref.current?.contains(event.target as Node)
        )
      ) {
        // Clicked outside any of the buttons
        setOpenPopperId(null)
        setIsPopperOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [buttonRefs])

  useEffect(() => {
    if (deviceUrlMappingListIsError) {
      toast.error('Could not load device url list at this time')
    }
  }, [deviceUrlMappingListIsError])

  useEffect(() => {
    if (isEditSuccess) {
      setUpdateDeviceUrlMappingModal({
        state: false,
        id: 0
      })
      toast.success(editData?.data?.message)
      void trigger(filterQuery, false)
    }
    if (isEditError) {
      console.log(editError)
      if (editError?.data?.message) {
        toast.error(editError?.data?.message)
      } else if (editError?.message) {
        toast.error(editError?.message)
      } else {
        toast.error('URL has been failed to saved.')
      }
    }
  }, [isEditSuccess, isEditError, editData, trigger, filterQuery, editError])

  useEffect(() => {
    if (isCreateSuccess) {
      setAddDeviceUrlMappingModal(false)
      toast.success(createData?.data?.message)
      void trigger(filterQuery, false)
    }

    if (isCreateError) {
      if (createError?.data?.message) {
        toast.error(createError?.data?.message)
      } else {
        toast.error('URL has been failed to saved.')
      }
    }
  }, [
    isCreateSuccess,
    isCreateError,
    createData,
    trigger,
    filterQuery,
    createError
  ])

  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteDeviceUrlMappingModal((prevState) => ({
        ...prevState,
        state: false
      }))
      toast.success('URL has been successfully Deleted.')
      void trigger(filterQuery, false)
    }

    if (isDeleteError) {
      if (deleteError?.message) {
        toast.error(deleteError?.message)
      } else if (deleteError?.data?.message) {
        toast.error(deleteError?.data?.message)
      } else {
        toast.error('URL has been failed to delete.')
      }
    }
  }, [
    isDeleteSuccess,
    isDeleteError,
    deleteData,
    trigger,
    filterQuery,
    deleteError
  ])

  const handleEditUrl = () => {
    const value: IDeivceImportPayload = {
      url: getValues('url') as string,
      endPointName: getValues('endpoint_name') as string,
      serverRegion: getValues('server_region') as string,
      serverName: getValues('server_name') as string
    }

    if (updateDeviceUrlMappingModal.state) {
      void editDeviceUrlMapping({
        ...value,
        id: updateDeviceUrlMappingModal.id
      })
    }

    if (addDeviceUrlMappingModal) {
      void createDeviceUrlMapping({ ...value })
    }

    return true
  }

  const handleDeleteUrl = () => {
    const value = {
      deviceUrlId: deleteDeviceUrlMappingModal.deviceUrlMappingId
    }
    void deleteDeviceUrlMapping(value)
  }

  const getModalTitle = () => {
    if (addDeviceUrlMappingModal) {
      return 'Add New URL'
    } else if (updateDeviceUrlMappingModal.state) {
      return 'Edit URL'
    }
    return ''
  }

  return (
    <PageTemplate>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <CustomButton
          onClick={() => {
            setValue('server_name', '')
            setValue('server_region', '')
            setValue('endpoint_name', '')
            setValue('url', '')
            setAddDeviceUrlMappingModal(true)
          }}
          className={classes.newImportBtn}
          color='primary'
          // onClick={handleOpenModal}
          disabled={
            deviceUrlMappingListData?.data.total_device_mapping_list === 0
          }
          sx={{
            padding: '10px 15px 10px 15px !important',
            width: '111px !important',
            // justifyContent: "unset"
            gap: '0 !important'
          }}
        >
          <Box>
            <AddIcon style={{ transform: 'scale(0.875)' }} />
          </Box>
          <Typography
            sx={{
              lineHeight: '14px'
            }}
          >
            {t('deviceUrlMapping.addUrl')}
          </Typography>
        </CustomButton>
      </Box>
      <CommonDialog
        open={deleteDeviceUrlMappingModal.state}
        handleClose={() => {
          setDeleteDeviceUrlMappingModal({
            state: false,
            deviceUrlMappingId: 0
          })
        }}
        handleSubmit={handleDeleteUrl}
        icon={DeleteIconModal}
        title='Are you sure you want to delete this URL?'
        description='This action cannot be undone.'
        cancelText='Cancel'
        successText='Delete'
        isModalTop
        isLoading={isDeleteLoading}
      />
      <DeviceUrlMappingForm
        loading={isCreateLoading || isEditLoading}
        title={getModalTitle()}
        handleClose={() => {
          if (updateDeviceUrlMappingModal.state) {
            setUpdateDeviceUrlMappingModal((prevState) => ({
              ...prevState,
              state: false
            }))
          } else if (addDeviceUrlMappingModal) {
            setAddDeviceUrlMappingModal(false)
          }
        }}
        open={updateDeviceUrlMappingModal.state || addDeviceUrlMappingModal}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            margin: '15px 0 20px 0'
          }}
        >
          <div>
            <CustomSelect
              disabled={false}
              placeholder='Select Server Name'
              selectedOption={
                getValues('server_name')
                  ? {
                      value: getValues('server_name') as string,
                      label: urlTypeListData
                        ? urlTypeListData.data.device_url_type_list.filter(
                            (element) => {
                              return (
                                element.url_type === getValues('server_name')
                              )
                            }
                          )[0]?.url_type
                        : ''
                    }
                  : undefined
              }
              optionChangeHandler={(option) => {
                setValue('server_name', option.label)
              }}
              options={
                urlTypeListData
                  ? urlTypeListData.data.device_url_type_list.map(
                      ({ url_type }) => ({
                        value: String(url_type),
                        label: url_type
                      })
                    )
                  : []
              }
              labelText='Server Name'
              required={false}
              // defaultValue={serverNameOptions[0]}
              name='server_name'
            />
          </div>
          <div>
            <CustomSelect
              disabled={false}
              placeholder='Select Server Region'
              selectedOption={
                getValues('server_region')
                  ? {
                      value: getValues('server_region') as string,
                      label: serverRegionListData
                        ? serverRegionListData.details.filter(
                            (element) =>
                              String(element.server_region_name) ===
                              getValues('server_region')
                          )[0]?.server_region_name
                        : ''
                    }
                  : undefined
              }
              optionChangeHandler={(option: OptionsType) => {
                setValue('server_region', option.label)
              }}
              options={
                serverRegionListData
                  ? serverRegionListData.details.map(
                      ({ server_region_id, server_region_name }) => ({
                        label: server_region_name,
                        value: String(server_region_id)
                      })
                    )
                  : []
              }
              labelText='Server Region'
              required={false}
              // defaultValue={serverRegionOptions[0]}
              name='server_region'
            />
          </div>
          <CustomInput
            register={register}
            registerField='endpoint_name'
            label='Endpoint Name'
            placeholder='Type Endpoint Name'
            errors={errors}
            required={false}
            min={1}
            max={255}
          />
          <CustomInput
            register={register}
            registerField='url'
            label='URL'
            placeholder='Type URL'
            errors={errors}
            required={false}
            min={1}
            max={255}
          />
        </Box>
        <Divider
          className={classes.actions_row_divider}
          sx={{ marginTop: '0px !important' }}
        />
        <div className={classes.actions_row}>
          <div className={classes.actions_row_buttons}>
            {!addDeviceUrlMappingModal && !updateDeviceUrlMappingModal.state ? (
              <Skeleton sx={{ height: '44px' }} />
            ) : (
              <>
                <CustomButton
                  className={modalClass.cancelBtn}
                  onClick={() => {
                    setUpdateDeviceUrlMappingModal((prevState) => ({
                      ...prevState,
                      state: false
                    }))
                    setAddDeviceUrlMappingModal(false)
                    setValue('server_name', '')
                    setValue('server_region', '')
                    setValue('endpoint_name', '')
                    setValue('url', '')
                  }}
                  color='secondary'
                >
                  {t('userManagement.cancel')}
                </CustomButton>
                <CustomButton
                  type='submit'
                  color='primary'
                  className={modalClass.importBtn}
                  onClick={handleEditUrl}
                  disabled={!isEditable && updateDeviceUrlMappingModal.state}
                >
                  {addDeviceUrlMappingModal ? 'Add URL' : null}
                  {updateDeviceUrlMappingModal.state ? 'Save' : null}
                </CustomButton>
              </>
            )}
          </div>
        </div>
      </DeviceUrlMappingForm>
      <Box className={classes.tableCard}>
        <div>
          <Typography className={classes.tableDataCount}>
            {`${t('deviceUrlMapping.headerTitle')} `}(
            {deviceUrlMappingListData?.data.total_device_mapping_list ?? 0})
          </Typography>
        </div>

        {deviceUrlMappingListData?.data.total_device_mapping_list === 0 ? (
          <div className={classes.dataNotFoundBlock}>
            <Corporation />
            <p className={classes.noFountText}>
              {t('deviceUrlMapping.noDataTitle')}
            </p>
            <p className={classes.noAvailableText}>
              {t('deviceUrlMapping.currentlyNoDeviceUrlMapping')}
            </p>
          </div>
        ) : (
          <Box className={classes.dataTableWrap}>
            <SimpleBar>
              <Table tableWidth='1520px'>
                <THeader
                  clickSortButton={onClickSortButton}
                  columnProperties={columnProps}
                  sortConfig={sortConfig}
                />
                <TBody
                  columnProperties={columnProps}
                  isSuccess={deviceUrlMappingListIsSuccess}
                >
                  {deviceUrlMappingListData?.data.device_mapping_list.map(
                    ({
                      id,
                      endpoint_name,
                      server_name,
                      server_region,
                      url
                    }) => (
                      <TRow index={id} key={id}>
                        <TCell
                          colMaxWidth={columnProps[0].colMaxWidth}
                          colWidth={columnProps[0].colWidth}
                          isReloading={deviceUrlMappingListIsLoading}
                          key='server_name'
                        >
                          {server_name}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[1].colMaxWidth}
                          colWidth={columnProps[1].colWidth}
                          isReloading={deviceUrlMappingListIsLoading}
                          key='server_region'
                        >
                          {server_region}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[2].colMaxWidth}
                          colWidth={columnProps[2].colWidth}
                          isReloading={deviceUrlMappingListIsLoading}
                          key='endpoint_name'
                        >
                          {endpoint_name}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[3].colMaxWidth}
                          colWidth={columnProps[3].colWidth}
                          isReloading={deviceUrlMappingListIsLoading}
                          key='url'
                        >
                          {url ? (
                            <CustomTooltip
                              fontSize='14px'
                              toolTipTitle={url}
                              toolTipWidth='405px'
                            />
                          ) : (
                            '-'
                          )}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[4].colMaxWidth}
                          colWidth={columnProps[4].colWidth}
                          isReloading={deviceUrlMappingListIsLoading}
                          key='delete'
                        >
                          <Box className={classes.deleteIconBox}>
                            <Button
                              id='deleteButton'
                              variant='contained'
                              className={classes.deleteIconBtn}
                              aria-controls={`simple-popper-${String(id)}`}
                              aria-describedby={`simple-popper-${String(id)}`}
                              aria-haspopup='true'
                              onClick={(event) => {
                                event.stopPropagation()
                                handleDeleteMenuOpen(event, String(id))
                              }}
                              ref={buttonRefs.current[id]} // Use the ref corresponding to the current row
                            >
                              <MenuIcon />
                            </Button>
                            {openPopperId === String(id) && isPopperOpen && (
                              <Box
                                className={classes.deletePopper}
                                sx={{ width: '100px' }}
                              >
                                <ListItemButton
                                  disableRipple
                                  onClick={() => {
                                    setUpdateDeviceUrlMappingModal({
                                      state: true,
                                      id
                                    })
                                    setValue('server_name', server_name)
                                    setValue('server_region', server_region)
                                    setValue('endpoint_name', endpoint_name)
                                    setValue('url', url)
                                  }}
                                  className={classes.popperBtn}
                                  sx={{ height: '36.5px' }}
                                >
                                  <Edit />
                                  <p style={{ padding: '0', margin: '0' }}>
                                    Edit
                                  </p>
                                </ListItemButton>
                                <ListItemButton
                                  disableRipple
                                  onClick={() => {
                                    setDeleteDeviceUrlMappingModal({
                                      state: true,
                                      deviceUrlMappingId: id
                                    })
                                  }}
                                  className={classes.popperBtn}
                                  sx={{ height: '36.5px' }}
                                >
                                  <Delete />
                                  <p style={{ padding: '0', margin: '0' }}>
                                    Delete
                                  </p>
                                </ListItemButton>
                              </Box>
                            )}
                          </Box>
                        </TCell>
                      </TRow>
                    )
                  )}
                </TBody>
              </Table>
            </SimpleBar>
            {deviceUrlMappingListIsFetching && deviceUrlMappingListIsSuccess ? (
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  top:
                    deviceUrlMappingListData?.data.total_device_mapping_list ===
                    1
                      ? '10%'
                      : deviceUrlMappingListData?.data
                          .total_device_mapping_list === 2
                      ? '25%'
                      : '45%',
                  left: '50%',
                  zIndex: '1'
                }}
              >
                <Loader colorCode='#939598' />
              </Box>
            ) : null}
            <div className={classes.dataPagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  (deviceUrlMappingListData && deviceUrlMappingListData.data
                    ? deviceUrlMappingListData.data.total_device_mapping_list ??
                      0
                    : 0) / rowsPerPage
                )}
                clickPrevPageButton={() => {
                  onClickPrevPage()
                }}
                clickNextPageButton={() => {
                  onClickNextPage()
                }}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={rowsPerPage}
                changeRowNumber={onChangeRowNumber}
                containFirstAndLastButton
                clickFirstPageButton={onClickFirstPage}
                clickLastPageButton={onClickLastPage}
                pageDataIndexFrom={currentPage * rowsPerPage + 1}
                pageDataIndexTo={
                  // eslint-disable-next-line no-nested-ternary
                  (currentPage + 1) * rowsPerPage >
                  (deviceUrlMappingListData
                    ? deviceUrlMappingListData.data.total_device_mapping_list
                    : 0)
                    ? deviceUrlMappingListData
                      ? deviceUrlMappingListData.data.total_device_mapping_list
                      : 0
                    : (currentPage + 1) * rowsPerPage
                }
                totalDataRows={
                  deviceUrlMappingListData
                    ? deviceUrlMappingListData.data.total_device_mapping_list
                    : 0
                }
              />
            </div>
          </Box>
        )}
      </Box>
    </PageTemplate>
  )
}

export default DeviceUrlMappingList

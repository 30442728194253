import React, { useState } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { Box, Typography } from '@mui/material'
import SimpleBarReact from 'simplebar-react'
import { makeStyles } from '@mui/styles'
import CommonDialog from './Dialog'
import { ReactComponent as DeleteIconModal } from '../../images/icon/modal-delete.svg'
import {
  Item_Code,
  MAX_FILE_UPLOAD_SIZE,
  Product_Types,
  modeEnums
} from '../../constants/constants'
import readAndProcessFile from '../../utils/checkEncoding'
import CustomTooltip from './Tooltip'

import { ReactComponent as DeleteIcon } from '../../images/icon/delete_white.svg'
import { ReactComponent as CrossWhite } from '../../images/icon/cross-white.svg'
import { ReactComponent as ErrorSign } from '../../images/icon/error-sign-manage-groups.svg'
import { ReactComponent as ShowMoreIcon } from '../../images/icon/show-more-down.svg'
import { ReactComponent as ShowUpIcon } from '../../images/icon/show-more-up.svg'
import { csvFileContent } from '../../utils/utility'

interface DropFileUploadProps {
  onChange: (file: File | null) => void
  onRemove: () => void
  title: string
  titleMiddle: string
  titleBottom: string
  disabled: boolean
  defValue: File | '' | null
  acceptedFilesTypes: string[]
  inputFieldMaxWidth: string
  inputFieldMaxFileSize: number
  multipleFilesError: string
  fileTypeError: string
  fileSizeError: string
  encodeError: string
  titleError: string
  isLoading: boolean
}

const DropFileUpload: React.FC<DropFileUploadProps> = ({
  onChange,
  onRemove,
  disabled,
  title,
  defValue,
  titleMiddle,
  titleBottom,
  acceptedFilesTypes,
  inputFieldMaxWidth,
  inputFieldMaxFileSize,
  multipleFilesError,
  fileTypeError,
  fileSizeError,
  encodeError,
  titleError,
  isLoading
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [fileError, setFileError] = useState<{
    isError: boolean
    message: string | { title?: string; messages?: string[] }
  }>({
    isError: false,
    message: ''
  })
  const [isShowMore, setIsShowMore] = useState<boolean>(false)

  const useStyles = makeStyles({
    dropzone: {
      width: '100%',
      minWidth: inputFieldMaxWidth || '100%',
      height: '34px',
      border: '1px solid #D9D9D9',
      borderRadius: '3px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px !important'
    },
    deleteFile: {
      width: '48px',
      height: '26px',
      backgroundColor: '#C5C5C5',
      borderRadius: '1px 4px 4px 1px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: defValue ? 'pointer' : 'default',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: isLoading ? '#C5C5C5' : '#E1341E'
      }
    },
    errorContainer: {
      // display: 'flex',
      // justifyContent: 'space-between',
      // alignItems: 'center',

      backgroundColor: '#E1341E',
      color: 'white',
      borderRadius: '3px',
      padding: '9px 16px',
      marginTop: '2px 0 !important',
      position: 'relative'
    },
    dropFileContainer: {
      height: '108px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '4px'
    }
  })
  const classes = useStyles()

  const deleteHandleModal = () => {
    onChange(null)
    setDeleteDialogOpen(false)
    onRemove()
    setFileError({
      isError: false,
      message: ''
    })
  }

  const isEmpty = (value: string | null | undefined): boolean =>
    value === '' || value === null || value === undefined

  const checkIfNonNegativeNumber = (
    value: string | null | undefined
  ): boolean =>
    !isEmpty(value) && !Number.isNaN(Number(value)) && Number(value) >= 0

  const checkIsString = (value: string | null | undefined): boolean =>
    !isEmpty(value) && typeof value === 'string'

  const validateCsvFile = async (file: File) => {
    const errors = []

    try {
      const {    csvFileContentConverted: rows } = await csvFileContent(file)

      if (rows.length > 0) {
        for (let index = 0; index < (rows?.length ?? 0); index += 1) {
          const row = rows[index]

          if (row.length !== 9) {
            errors.push(`Line ${index + 1}: Row must have exactly 9 elements`)
          }

          const [
            corp_id_f,
            corp_name_f,
            sales_region_f,
            esim_val_f,
            imei1_f,
            imei2_f,
            product_type_f,
            mode_f,
            overwrite_sim_f
          ] = row

          const allEmpty = row.every((element) => element === '')

          if (allEmpty) {
            errors.push(`Line ${index + 1}: Row must have at least one element`)
          }

          // corp_id
          if (corp_id_f !== '' && checkIsString(corp_id_f) === false) {
            errors.push(`Line ${index + 1}:  Corp Id must be a string`)
          }
          if (typeof corp_id_f === 'string' && corp_id_f.length > 20) {
            errors.push(
              `Line ${
                index + 1
              }:  Corp Id must be less than or equal to 20 characters`
            )
          }

          // corp_name
          if (checkIsString(corp_name_f) === false) {
            errors.push(`Line ${index + 1}:  Corp Name must be a string`)
          }

          if (typeof corp_name_f === 'string' && corp_name_f.length > 255) {
            errors.push(
              `Line ${
                index + 1
              }:  Corp Name must be less than or equal to 255 characters`
            )
          }

          // Sales Region
          if (checkIfNonNegativeNumber(sales_region_f) === false) {
            errors.push(
              `Line ${index + 1}: Sales Region must be a non-negative number`
            )
          }

          // eSIM Valid Duration

          if (checkIfNonNegativeNumber(esim_val_f) === false) {
            errors.push(
              `Line ${
                index + 1
              }: eSIM Valid Duration must be a non-negative number`
            )
          }

          // imei1 and imei2
          const is15DigitNumber = (value: string) => /^\d{15}$/.test(value)

          if (!is15DigitNumber(imei1_f)) {
            errors.push(`Line ${index + 1}: IMEI1 must be 15-digit numbers`)
          }

          if (
            ![1, 2, 3].includes(Number(product_type_f)) &&
            !is15DigitNumber(imei2_f)
          ) {
            errors.push(
              `Line ${
                index + 1
              }: IMEI2 must be 15-digit numbers for product type 4 and 5`
            )
          }

          if (imei2_f && !is15DigitNumber(imei2_f)) {
            errors.push(`Line ${index + 1}: IMEI2 must be 15-digit numbers`)
          }

          // product_type_f

          if (checkIfNonNegativeNumber(product_type_f) === false) {
            errors.push(
              `Line ${index + 1}: Product Type must be a non-negative number`
            )
          }
          // csvDataFormat.Product_Type.keys
          const Product_Types_Enums = new Set(Object.keys(Product_Types))

          if (!Product_Types_Enums.has(product_type_f)) {
            const fifthKeysArray = Array.from(Product_Types_Enums)

            errors.push(
              `Line ${
                index + 1
              }: Product Type must be one of: ${fifthKeysArray.join(', ')}`
            )
          }

          // Mode

          if (checkIfNonNegativeNumber(mode_f) === false || mode_f === '') {
            errors.push(`Line ${index + 1}: Mode must be a non-negative number`)
          } else if (modeEnums[Number(mode_f)] === undefined) {
            errors.push(
              `Line ${index + 1}: Mode must be one of: ${Object.keys(
                modeEnums
              ).join(', ')}`
            )
          }

          // overwrite_sim

          if (checkIfNonNegativeNumber(overwrite_sim_f) === false) {
            errors.push(
              `Line ${index + 1}: Overwrite sim must be a non-negative number`
            )
          }
        }
      } else {
        errors.push('CSV file is empty')
      }
    } catch (error) {
      errors.push('Error handling uploaded CSV file')
    }

    return errors
  }

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length !== 1) {
      setFileError({
        isError: true,
        message: multipleFilesError
      })

      return
    }

    if (acceptedFiles[0].type !== 'text/csv') {
      setFileError({
        isError: true,
        message: fileTypeError
      })

      return
    }

    setIsShowMore(false)

    const uploadedFile = acceptedFiles[0]
    const fileNameParts = uploadedFile?.name?.split('.')
    const fileExtension = fileNameParts[fileNameParts.length - 1]?.toLowerCase()

    const reader = new FileReader()
    reader.onload = async () => {
      const utf8Encoded = await readAndProcessFile(uploadedFile)
      if (
        acceptedFilesTypes &&
        acceptedFilesTypes?.length > 0 &&
        !acceptedFilesTypes?.includes(fileExtension)
      ) {
        setFileError({
          isError: true,
          message: fileTypeError
        })
      } else if (utf8Encoded !== 'UTF-8-with-BOM') {
        setFileError({
          isError: true,
          message: encodeError
        })
      } else if (
        uploadedFile.size > (inputFieldMaxFileSize || MAX_FILE_UPLOAD_SIZE)
      ) {
        setFileError({
          isError: true,
          message: fileSizeError
        })
      } else {
        const { csvFileContentConverted, quoteCheck ,hasEmptyRow} = await csvFileContent(
          uploadedFile
        )
        if (csvFileContentConverted.join('') === '') {
          setFileError({
            isError: true,
            message: 'Error: The uploaded file is empty.'
          })
        } 
        else if (hasEmptyRow) {
          setFileError({
            isError: true,
            message: 'Error: The uploaded file has empty rows.'
          })
        }
        else if (!quoteCheck) {
          setFileError({
            isError: true,
            message: 'Error: The uploaded file must contain only double quotes.'
          })
        } else {
          const errors = await validateCsvFile(uploadedFile)

          if (errors.length > 0) {
            setFileError({
              isError: true,
              message: {
                title: 'Error: The uploaded file has some troubles.',
                messages: errors
              }
            })
          } else {
            onChange(uploadedFile)
            setFileError({ isError: false, message: '' })
          }
        }
      }
    }
    reader.readAsText(uploadedFile)
  }

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    disabled,
    multiple: false
  }

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions)

  const { onClick: onInputClick } = getRootProps()

  const checkTextLines = ({
    fileErrorData
  }: {
    fileErrorData: {
      isError: boolean
      message: string | { title?: string; messages?: string[] }
    }
  }): number => {
    let countLines = 0

    if (
      typeof fileErrorData?.message !== 'string' &&
      fileErrorData?.message?.messages
    ) {
      fileErrorData?.message?.messages?.forEach((line) => {
        countLines += line?.length > 65 ? Math.ceil(line.length / 65) : 1
      })
    }

    return countLines
  }

  return (
    <Box>
      {defValue ? (
        <Box className={classes.dropzone}>
          <Typography
            variant='body1'
            component='p'
            sx={{
              paddingLeft: '6px',
              font: 'normal normal normal 14px/14px Noto Sans JP'
            }}
          >
            <CustomTooltip toolTipTitle={defValue?.name} maxWidth={250} />
          </Typography>

          <Box
            className={classes.deleteFile}
            onClick={() => {
              if (isLoading) {
                return
              }
              setDeleteDialogOpen(true)
            }}
            aria-label='Delete file'
          >
            <DeleteIcon style={{ color: '#24ABE5' }} />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: isDragActive ? '#F3FCFF' : '#FFFFFF',
            color: 'gray',
            border: '2px dashed #D9D9D9',
            outline: 'none',
            transition: 'background-color 0.2s ease',
            padding: '4px',
            borderRadius: '3px',
            maxWidth: '100%'
          }}
        >
          {fileError.isError ? (
            <Box className={classes.errorContainer}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    flex: 1
                  }}
                >
                  <ErrorSign color='white' />
                  <Typography variant='body2' component='p'>
                    {typeof fileError?.message === 'string'
                      ? fileError?.message
                      : fileError?.message.title}
                  </Typography>
                </Box>
                <CrossWhite
                  style={{ cursor: 'pointer' }}
                  color='white'
                  onClick={() => {
                    setFileError({ isError: false, message: '' })
                  }}
                  aria-label='Close error message'
                />
              </Box>

              {typeof fileError.message === 'string' ? null : (
                <SimpleBarReact
                  style={{
                    overflow: isShowMore ? 'auto' : 'hidden',
                    marginTop: '6px',
                    maxHeight: isShowMore ? '106px' : '54px',
                    backgroundColor: '#E1341E',
                    color: 'white'
                  }}
                >
                  {typeof fileError?.message !== 'string' && isShowMore
                    ? fileError?.message?.messages?.map((message) => (
                        <Typography
                          key={message}
                          style={{
                            textAlign: 'left',
                            fontSize: '11px',
                            letterSpacing: '0px',
                            paddingLeft: '50px',
                            lineHeight: '18px',
                            display: '-webkit-box',
                            WebkitLineClamp: isShowMore ? 3 : 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            maxWidth: '80%'
                          }}
                        >
                          {message}
                        </Typography>
                      ))
                    : fileError?.message?.messages
                        ?.slice(0, 3)
                        ?.map((message) => (
                          <Typography
                            key={message}
                            style={{
                              textAlign: 'left',
                              fontSize: '11px',
                              letterSpacing: '0px',
                              paddingLeft: '50px',
                              lineHeight: '18px',
                              maxWidth: '80%'
                            }}
                          >
                            {message}
                          </Typography>
                        ))}
                </SimpleBarReact>
              )}

              {typeof fileError?.message !== 'string' &&
                fileError?.message?.messages &&
                checkTextLines({
                  fileErrorData: fileError
                }) > 3 && (
                  <Box
                    onClick={() => setIsShowMore(!isShowMore)}
                    style={{
                      position: 'absolute',
                      top: '72px',
                      height: '16px',
                      right: '16px',
                      lineHeight: '18px'
                    }}
                  >
                    <Typography
                      variant='body2'
                      component='p'
                      sx={{
                        cursor: 'pointer',
                        color: 'white',
                        fontFamily: 'Noto Sans JP',
                        fontSize: '11px',
                        letterSpacing: '0px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '13px',
                        lineHeight: 'normal'
                      }}
                    >
                      {isShowMore ? 'See less' : 'See More'}
                      {isShowMore ? <ShowUpIcon /> : <ShowMoreIcon />}
                    </Typography>
                  </Box>
                )}
            </Box>
          ) : null}

          <Box
            className={classes.dropFileContainer}
            aria-label='Drop file here'
            // eslint-disable-next-line
            {...getRootProps({
              onClick: (e) => {
                e.stopPropagation()
              }
            })}
          >
            {/* eslint-disable-next-line */}
            <input {...getInputProps()} type='file' accept='.csv' />
            <Typography
              variant='body2'
              component='p'
              sx={{
                font: 'normal normal bold 14px/20px Noto Sans JP',
                letterSpacing: '0.1px',
                color: '#313133',
                textAlign: 'center'
              }}
            >
              {fileError?.isError ? titleError : title}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', color: '#313133' }}
              variant='body1'
              component='p'
            >
              {titleMiddle}
            </Typography>
            <Typography
              variant='body2'
              component='p'
              sx={{
                color: '#009DE1',
                fontSize: '14px',
                cursor: 'pointer',
                '&:hover': {
                  color: '#007EB4'
                }
              }}
              onClick={onInputClick}
            >
              {titleBottom}
            </Typography>
          </Box>
        </Box>
      )}

      <CommonDialog
        open={deleteDialogOpen}
        handleClose={() => {
          setDeleteDialogOpen(false)
        }}
        handleSubmit={() => {
          deleteHandleModal()
        }}
        icon={DeleteIconModal}
        title='Are you sure you want to delete this file?'
        description='This action cannot be undone.'
        cancelText='Cancel'
        successText='Delete'
      />
    </Box>
  )
}

export default DropFileUpload
